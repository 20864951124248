import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch, TypedUseSelectorHook } from "react-redux"
import { notificationsSlice } from "@/shared/lib/redux/slices/notifications"
import { api } from "@/shared/lib/redux/slices/api-slice"

export type ReduxStore = typeof reduxStore
export type ReduxState = ReturnType<typeof reduxStore.getState>
export type ReduxDispatch = typeof reduxStore.dispatch
export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, Action>

const reducer = {
  notifications: notificationsSlice.reducer,
  [api.reducerPath]: api.reducer,
}

export function setupStore() {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  })
}

export const reduxStore = setupStore()

export const useDispatch = useReduxDispatch<ReduxDispatch>
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector
